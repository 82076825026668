import { useRouter } from '@nintendo-of-america/next';
import {
  SearchProvider,
  CATEGORIES,
  GAMES_FILTERS,
} from '@nintendo-of-america/search';
import { PAGE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import getPageBySlug from '@local/lib/contentful/api/getPageBySlug';
import getPageProps from '@local/lib/api/getPageProps';
import { PLP_PARAMS_WHITELIST, PASSTHROUGH_PARAMS } from '@local/lib/constants';
import PLPTemplate from '@local/components/pages/PLP/PLPTemplate';
import { plpProptypes } from '@nintendo-of-america/contentful-api/parsers';
import { getCategoryConfig } from '@local/lib/helpers';
import { getGraph } from '@nintendo-of-america/graph-api';

export async function getServerSideProps(context) {
  const { res, locale } = context;
  const graph = getGraph(context);
  const page = await getPageBySlug({
    slug: '/games/',
    locale,
    pageContentType: PAGE_TYPES.PLP,
    graph,
  });

  return getPageProps({
    page,
    analytics: {
      pageType: 'Merchandising',
      pageName: 'Games',
      pageCategory: 'Store',
    },
    res,
  });
}

function Games({ page }) {
  const productsCategoryUid = page.content?.productsCategoryUid;

  const router = useRouter();
  const breadcrumbLinks = [
    { href: '/', title: 'Home' },
    { href: '/store', title: 'My Nintendo Store' },
    { href: '/store/games', title: 'Games' },
  ];

  const category = CATEGORIES.GAMES;
  const categoryConfig = getCategoryConfig({
    category,
    productsCategoryUid,
    merchandisedGrid: page?.content?.merchandisedGrid,
    router,
  });

  return (
    <SearchProvider
      router={router}
      categoryConfig={categoryConfig}
      defaultCategory={category}
      searchParamWhitelist={PLP_PARAMS_WHITELIST}
      preserveQueryParamKeys={PASSTHROUGH_PARAMS}
    >
      <PLPTemplate
        breadcrumbLinks={breadcrumbLinks}
        page={page.content}
        pageIcon="d-pad"
        cachePrefix="Games"
        filterSections={GAMES_FILTERS}
        analytics={{
          eventPrefix: 'Games',
          pageName: 'Games PLP',
        }}
        fullBleedHero
      />
    </SearchProvider>
  );
}

Games.propTypes = {
  page: plpProptypes,
};

export default Games;
